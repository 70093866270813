<template>
    <a-card>
        <a-page-header
            title="广告主素材统计"
        />

        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
        >
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="广告主">
                        <a-input
                            v-model="form.advertiserName"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col class="flex-end">
                    <a-space>
                        <a-button @click="handleReset">重置</a-button>
                        <a-button type="primary" @click="handleSearch">查询</a-button>
                    </a-space>
                </a-col>
            </a-row>
        </a-form-model>

        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :rowKey="record => record.id"
            :pagination='false'
            class="mt-20"
        >
        </a-table>

        <base-pagination
            :currentPage="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        />

        <div class="mt-20">PS：数据每天凌晨更新1次</div>
    </a-card>
</template>

<script>
import utils from "@/utils/FormatUtil.js";

export default {
    data() {
        return {
            layout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 }
            },
            form: {
                principalIdList: [],
                customerSceneList: [],
                carSeriesIdList: []
            },
            loading: false,
            columns: [
                {
                    align: 'center',
                    title: '广告主',
                    dataIndex: 'advertiserName',
                    align: 'left',
                    customRender: (text, row) => {
                        return <div>
                            <div>{ row.advertiserName || '-' }</div>
                            <div class="txt">平台账套名：{ row.adPlatformAccountName || '-' }</div>
                        </div>
                    }
                },
                {
                    align: 'center',
                    title: '品牌',
                    dataIndex: 'principalName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '巨量广告视频数',
                    dataIndex: 'oceanVideoNum',
                    customRender: (text, row) => {
                        return (row.oceanVideoNum ?? '') !== '' ? utils.humanReadable(row.oceanVideoNum) : '-'
                    }
                },
                {
                    align: 'center',
                    title: '橙子建站程序化落地页数',
                    dataIndex: 'orangeLandingGroupNum',
                    customRender: (text, row) => {
                        return (row.orangeLandingGroupNum ?? '') !== '' ? utils.humanReadable(row.orangeLandingGroupNum) : '-'
                    }
                },
                {
                    align: 'center',
                    title: '巨量创意组件数',
                    dataIndex: 'creativeComponentNum',
                    customRender: (text, row) => {
                        return (row.creativeComponentNum ?? '') !== '' ? utils.humanReadable(row.creativeComponentNum) : '-'
                    }
                },
                {
                    align: 'center',
                    title: '青鸟线索通表单数',
                    dataIndex: 'clueFormNum',
                    customRender: (text, row) => {
                        return (row.clueFormNum ?? '') !== '' ? utils.humanReadable(row.clueFormNum) : '-'
                    }
                },
                {
                    align: 'center',
                    title: '橙子建站落地页数',
                    dataIndex: 'orangeLandingPageNum',
                    customRender: (text, row) => {
                        return (row.orangeLandingPageNum ?? '') !== '' ? utils.humanReadable(row.orangeLandingPageNum) : '-'
                    }
                },
                {
                    align: 'center',
                    title: '巨量产品数',
                    dataIndex: 'oceanProductNum',
                    customRender: (text, row) => {
                        return (row.oceanProductNum ?? '') !== '' ? utils.humanReadable(row.oceanProductNum) : '-'
                    }
                },
            ],
            dataList: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            },
            principalList: [],
            seriesList: [],
            customerSceneList: [],
        }
    },
    created() {
        this.getDataList()
    },
    methods: {
        handleSearch() {
            this.pagination.current = 1
            this.getDataList()
        },
        handleReset() {
            this.form = {}
            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.getDataList()
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        getDataList() {
            this.loading = true
            const _form = this.form
            let params = {
                ..._form,
                size: this.pagination.pageSize,
                page: this.pagination.current,
            }
            this.$api.core.materialManage.getMaterialStatisticsList(params).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.list || []
                    this.pagination.total = res.data.total || 0
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                    console.error(`获取列表失败${res}`)
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}

.mt-20 {
    margin-top: 20px;
}
.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.txt {
    color: #ccc;
}
</style>